<template>
  <div :class="['mainHeader']">
    <div class="content">
      <div class="container">
        <div class="topWrap">
          <img style="widht:70px;height:70px;" :src="require('@/assets/imgs/comm/logo.png')" alt="" />
        </div>
        <div class="tabsContent">
          <Tabs />
          <div class="langWrap" @click="showSelect = !showSelect">
            <div class="langItem">
              <img
                :src="
                  require('@/assets/imgs/comm/flag-' +
                    (isVn ? 'VN' : (isEn ? 'UK' : 'CN')) +
                    '.png')
                "
                alt=""
              />
              <span>{{ isVn ? "Tiếng Việt" : (isEn ? "English" : "简体中文") }}</span>
            </div>
            <img
              :src="require('@/assets/imgs/comm/head-arrow-wrap.svg')"
              alt=""
            />
            <div v-if="showSelect" class="langSelect">
              <div class="langItem" @click="setLanguageType('CMN')">
                <img :src="require('@/assets/imgs/comm/flag-CN.png')" alt="" />
                <span>简体中文</span>
              </div>
              <div class="langItem" @click="setLanguageType('ENG')">
                <img :src="require('@/assets/imgs/comm/flag-UK.png')" alt="" />
                <span>English</span>
              </div>
              <div class="langItem" @click="setLanguageType('VIE')">
                <img :src="require('@/assets/imgs/comm/flag-VN.png')" alt="" />
                <span>Tiếng Việt</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bg container">
      <div class="header-main">
        <img
          class="cupTextImg"
          :src="
            require('@/assets/imgs/comm/cup-text' +
              ((isEn || isVn) ? '-en' : '') +
              '.svg')
          "
          alt
        />
        <img
          class="cupTextImgSub"
          :src="
            require('@/assets/imgs/comm/cup-text-sub.svg')
          "
          alt
        />
        <!-- <div class="header-title">{{ $t("documentTitle") }}</div> -->
        <div class="countDownWrap">
          <div class="finalWrap">{{$t("home.Final")}}</div>
          <div class="countDown">
            <div class="time">
              <span>{{ day[0] }}{{ day[1] }}</span>
              <div>{{ $t("home.Day") }}</div>
            </div>
            <div :class="['leftTitle', { isEn: isEn }, {isVn: isVn}]">
              <span>{{ $t("home.conntdown") }}</span>
            </div>
            <div class="time">
              <span>{{ hour[0] }}{{ hour[1] }}</span>
              <div>{{ $t("home.Hour") }}</div>
            </div>
            <div class="time">
              <span>{{ minute[0] }}{{ minute[1] }}</span>
              <div>{{ $t("home.Minute") }}</div>
            </div>
            <div class="time">
              <span>{{ second[0] }}{{ second[1] }}</span>
              <div>{{ $t("home.Second") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs";
import db from "@/utils/localStorage";
export default {
  name: "MainHeader",
  components: {
    Tabs,
  },
  data() {
    return {
      day: [0, 0],
      hour: [0, 0],
      minute: [0, 0],
      second: [0, 0],
      countDownTimer: null,
      showSelect: false,
    };
  },
  computed: {
    isEn() {
      return this.$i18n.locale == "en";
    },
    isVn() {
      return this.$i18n.locale == "vn";
    },
  },
  methods: {
    setLanguageType(languageType) {
      this.$router.replace({
        query: { ...this.$route.query, languageType: languageType },
      });
      db.save("languageType", languageType);
      this.$store.commit("common/SET_DATA", {
        name: "languageType",
        data: languageType,
      });
      this.$i18n.locale = languageType == "CMN" ? "zh" : "en";
      if(languageType == 'VIE'){
        this.$i18n.locale = 'vn'
      }
      location.reload();
    },
    countDownDate() {
      let d = new Date();
      let len = d.getTime();

      let offset = d.getTimezoneOffset() * 60000;
      let utcTime = len + offset;
      // 获取固定东八区的时间
      let now = new Date(utcTime + 3600000 * 8);
      let endDate = new Date("2024/7/15 8:00:00");
      let leftTime = endDate.getTime() - now.getTime(); //计算剩余的毫秒数
      if (leftTime <= 0) {
        leftTime = 0;
      }
      let leftsecond = parseInt(leftTime / 1000); //计算剩余的秒数
      let day = Math.floor(leftsecond / (60 * 60 * 24));
      let hour = Math.floor((leftsecond - day * 24 * 60 * 60) / 3600);
      let minute = Math.floor(
        (leftsecond - day * 24 * 60 * 60 - hour * 3600) / 60
      );
      let second = Math.floor((leftTime / 1000) % 60, 10);
      day = (day + "").length > 1 ? day + "" : "0" + day;
      hour = (hour + "").length > 1 ? hour + "" : "0" + hour;
      minute = (minute + "").length > 1 ? minute + "" : "0" + minute;
      second = (second + "").length > 1 ? second + "" : "0" + second;
      this.day = [day[0], day[1]];
      this.hour = [hour[0], hour[1]];
      this.minute = [minute[0], minute[1]];
      this.second = [second[0], second[1]];
    },
    startCountDown() {
      this.countDownTimer = setInterval(this.countDownDate, 1000);
    },
  },
  created() {
    this.startCountDown();
  },
  destroyed() {
    clearInterval(this.countDownTimer);
    this.countDownTimer = null;
  },
};
</script>

<style lang="scss" scoped>
.mainHeader {
  width: 100%;
  height: 546px;
  color: #fff;
  background: #FF1420 url(../../assets/imgs/comm/main-bg.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
  .header-main {
    // height: 667px;
    width: 580px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    .cupTextImg {
      // position: absolute;
      // top: 0;
      // left: 40%;
      // transform: translate(-50%, 0);
      // width: auto;
      // height: 189px;
    }
    .cupTextImgSub{
      margin-top: 18px;
    }
    .openTime {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .logo {
    width: 118px;
  }
  .content {
    // background: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(25px);
    height: 118px;
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .langWrap {
      display: flex;
      margin-left: 75px;
      cursor: pointer;
      position: relative;
      .langSelect {
        position: absolute;
        top: 30px;
        .langItem {
          background: #5482FF;
          color: #fff;
          &:hover {
            color: #FF1420;
          }
        }
      }
      .langItem {
        border: 1px solid #5482FF;
        width: 107px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 9px;
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
    .tabsContent {
      display: flex;
      align-items: center;
    }
    .timer {
      font-size: 14px;
      line-height: 20px;
    }
    .title {
      width: 320px;
      margin-top: 5px;
    }
    .titleImg {
      text-align: center;
      margin-top: 5px;
      img {
        width: 229px;
        height: 70px;
      }
    }
  }
  .header-bg {
    // background: #f8420c url(../../assets/imgs/comm/banner-bg-main.png) no-repeat;
    // background-size: auto 100%;
    // background-position: center;
    // position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 188px;
    padding-top: 120px;
    .header-title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      position: relative;
      margin: auto;
      top: -189px;
      right: -93px;
      width: 300px;
      text-align: center;
    }
    .countDownWrap {
      margin-top: 24px;
      width: 520px;
      height: 83px;
      padding: 10px 25px;
      border-radius: 20px;
      background: rgba(19, 19, 19, 0.40);
      backdrop-filter: blur(5px);
      .finalWrap{
        position: absolute;
        left: 50%;
        top: -13px;
        transform: translate(-50%, 0);
        padding: 0 25px;
        height: 26px;
        line-height: 24px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        font-size: 16px;
        color: #fff;
        background: #EA3443;
      }
    }
    .countDownText {
      color: #fff;
      font-size: 16px;
      font-family: "DIN";
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 10px;
    }
    .countDown {
      display: flex;
      align-items: center;
      height: 100%;
      display: flex;
      .leftTitle {
        width: 32px;
        padding: 0 8px;
        position: absolute;
        font-weight: 600;
        top: 0;
        bottom: 0;
        left: 0;
        background: #FF1420;
        display: flex;
        align-items: center;
        color: #fff;
        letter-spacing: 2px;
        font-size: 16px;
        border-radius: 20px 0 0 20px;
        &.isEn {
          letter-spacing: 0px;
          padding: 0;
          span {
            transform: rotate(90deg);
            white-space: nowrap;
            padding-top: 8px;
            margin-left: 5px;
          }
        }
        &.isVn{
          letter-spacing: 0px;
          padding: 0;
        font-size: 13px;
          span {
            transform: rotate(90deg);
            white-space: nowrap;
            padding-top: 8px;
            margin-left: -16px;
          }
        }
      }
      .time {
        flex: 1;
        color: #fff;
        font-size: 20px;
        font-family: "DIN";
        font-style: normal;
        font-weight: 400;
        text-align: center;
        border-left: 1px solid rgba(255, 255, 255, 0.20);
        &:first-child {
          margin: 0;
          border-left: 0;
        }
        span {
          color: #fff;
          font-family: "DIN";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
        }
        div {
          color: #fff;
          font-family: Diwan Kufi;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .dot {
        width: 4px;
        height: 4px;
        transform: rotate(45deg);
        background: #fff;
      }
    }
  }
}
.dark{
//   .mainHeader .header-bg .countDownWrap{
// background: rgba(0, 0, 0, 0.80);
// backdrop-filter: blur(5px);
// border: 1px solid  #2B2C30;
//   }
//   .mainHeader .header-bg .countDown .time span{
//     color: #fff;
//   }
//   .mainHeader .header-bg .countDown .time div{
//     color: #D2D4D9;
//   }
}
</style>
